import React from "react";
import  ReactDOM from "react-dom";
import styles from "./index.css"
import App from "./App"

import { CursorProvider } from "./context/Cursor";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CursorProvider>
     <React.StrictMode>
      <App />
    </React.StrictMode>
  </CursorProvider>
     
)
