import React,{useContext} from 'react'

import Social from "../components/Social"
import  MobileNav  from "../components/MobileNav"

import { Link } from 'react-router-dom'
import logo from '../img/logo.png'
import { CursorContext } from '../context/Cursor'


const Header = () => {
  const{mouseEnterHandler,mouseLeaveHandler} = useContext(CursorContext)
  return (
    <header className='fixed w-full px-[30px] lg:px-[100px] z-30 h-[100px] lg:-[140px] flex items-center '> 
      <div className='flex flex-col lg:flex-row lg:items-center w-full justify-between uppercase'>
      <Link 
       onMouseEnter={mouseEnterHandler}
           onMouseLeave={mouseLeaveHandler}
      className='max-w-[200px]'>
       <img className='h-20' src={logo} alt='/' />
      </Link>

      <nav 
       onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      className='hidden xl:flex gap-x-12 font-medium'>
       <Link to={'/'} className='text-[#696c6d] hover:text-[#fff] transition'>Home</Link>
       <Link to={'/about'} className='text-[#696c6d] hover:text-[#fff] transition'>About</Link>
       <Link to={'/projects'} className='text-[#696c6d] hover:text-[#fff] transition'>Projects</Link>
       <Link to={'/contact'} className='text-[#696c6d] hover:text-[#fff] transition'>Contact</Link>
      </nav>

      
      </div>
      <Social />
      <MobileNav />
    </header>
  )
}

export default Header
