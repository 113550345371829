import React,{useContext} from 'react'

import AboutImage from '../img/dp2.png'

import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

import {transition1} from '../transition'
import { CursorContext } from '../context/Cursor'


const About = () => {

  const{mouseEnterHandler,mouseLeaveHandler} = useContext(CursorContext)

  return (
    <motion.section
    initial={{opacity:0, y:'100%' }}
    animate={{opacity:1,y:0}}
    exit={{opacity:0,  y:'100%'}}
    transition={transition1}
     className='section'>
      <div 
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      className='container mx-auto h-full relative'>
        <div className=' flex flex-col lg:flex-row h-full items-center justify-center gap-x-24 lg:pt-16 lg:text-left text-center'>
          <motion.div
            initial={{opacity:0, y:'-80%' }}
            animate={{opacity:1,y:0}}
            exit={{opacity:0,  y:'80%'}}
            transition={transition1}
           className='flex-1 max-h-96 lg:max-h-max order-2 lg:order-none overflow-hidden'>
          <img className='lg:w-[32rem]'  src={AboutImage} alt='/'/>
          </motion.div>
          <div>
            <motion.div
            initial={{opacity:0, y:'-80%'}}
            animate={{opacity:1, y:0}}
            exit={{opacity:0, y:'-80%'}}
            transition={transition1}
             className='flex-1 pt-36 pb-14 lg:pt-24 lg:w-auto z-10 flex flex-col justify-center items-center lg:items-start'>
              <h1 className='h1'>About Me</h1>
              <p className='mb-12 max-w-sm'>As a dynamic college senior, I'm passionate about crafting seamless web experiences.<br /> <b>With expertise in MERN Stack.</b> I bring industry-best personal projects to life. <br/> <br/>I thrive on translating designs into efficient front-end code, balancing time, and costs for optimal results. Let's innovate together!"</p>
              <Link to={'/projects'} className='btn '>View my Work</Link>
            </motion.div>
          </div>
        </div>
      </div>
    </motion.section>
  )
}

export default About
