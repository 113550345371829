import React,{useContext} from 'react'

import ContactImg from '../img/dp.png'

import { motion } from 'framer-motion'

import {transition1} from '../transition'
import { CursorContext } from '../context/Cursor'

const Contact = () => {

  const{mouseEnterHandler,mouseLeaveHandler} = useContext(CursorContext)

  return (
    <motion.section 
     initial={{opacity:0, y:'100%' }}
     animate={{opacity:1,y:0}}
     exit={{opacity:0,  y:'100%'}}
     transition={transition1}
    className='section'>
      <div className='container mx-auto h-full'>
        <motion.div
         initial={{opacity:0, y:'-80%' }}
            animate={{opacity:1,y:0}}
            exit={{opacity:0,  y:'80%'}}
            transition={transition1}
         className='flex flex-col lg:flex-row h-full items-center justify-start pt-36 gap-x-8 text-center lg:text-left'>
        
          <div
           onMouseEnter={mouseEnterHandler}
           onMouseLeave={mouseLeaveHandler}
           className='lg:flex-1  lg:pt-32px px-4'>
          <h1 className='h1'>
             Contact Me
          </h1>
          <p className='mb-12'>
          Eager to discuss how I can elevate your digital presence? Contact me for inquiries, collaborations, or just to chat about your next project. I look forward to hearing from you
          </p>
          <form className='flex flex-col gap-y-4'> 
            <div className='flex gap-x-10'>
              <input className='outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3  placeholder:text-[#757879]' type='text' placeholder='Your name'/>
              <input className='outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3  placeholder:text-[#757879]' type='text' placeholder='Your email address'/>
              
            </div>
            <input className='outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3  placeholder:text-[#757879]' type='text' placeholder='Your message'/>
              <button className='btn mb-[30px] mx-auto lg:mx-0 self-start'>Send It</button>
          </form>
          </div>
          
        </motion.div>
      </div>
    </motion.section>
  )
}

export default Contact
