import  React,{useContext } from "react";

import Header from "./components/Header"
import  AnimationRoute  from "./components/AnimRoute"
import { BrowserRouter as Router } from "react-router-dom";

import {motion} from 'framer-motion'

import { CursorContext } from "./context/Cursor";



const App = () => {
 
  const {cursorVariants,cursorBG} = useContext(CursorContext)
  return (
    <>
      <Router>
        <Header />
        <AnimationRoute />
      </Router>
      <motion.div
      variants={cursorVariants}
      animate ={cursorBG}
      className="w-[32px] h-[32px] bg-primary fixed top-0 left-0 pointer-events-none rounded-full">

      </motion.div>
    </>
  );
}

export default App
