import React from 'react'
import {ImLinkedin ,ImGithub, ImInstagram , ImTwitter} from 'react-icons/im'

const Social = () => {
  return (
    <div className='hidden xl:flex ml-24'>
      <ul className='gap-4 flex '>
        <li>
          <a href='https://www.linkedin.com/feed/' target='_blank' ><ImLinkedin /></a>
         </li>
         <li>
         <a href='https://github.com/KunalwritesCode' target='_blank' ><ImGithub /></a>
          
         </li>
         <li>
         <a href='https://www.instagram.com/kunal.evolves/' target='_blank' ><ImInstagram /></a>
         
         </li>
         <li>
         <a href='https://twitter.com/KunalPidiha' target='_blank' ><ImTwitter /></a>
         </li>
      </ul>
    </div>
  )
}

export default Social
