import React ,{useContext}from 'react'

import HomeImg from '../img/dp2.png'

import { Link } from 'react-router-dom'

import {motion} from 'framer-motion'

import {transition1} from '../transition'
import { CursorContext } from '../context/Cursor'

const Home = () => {
  const {mouseEnterHandler,mouseLeaveHandler} = useContext(CursorContext)
  return (
  <motion.section 
    initial={{opacity:0}}
    animate={{opacity:1}}
    exit={{opacity:0}}
    transition={transition1}
    className='section'>
      <div className='container mx-auto h-full relative'>
        <div className='flex flex-col justify-center'>
          <motion.div 
            initial={{opacity:0,y:'-50%'}}
            animate={{opacity:1, y:0}}
            exit={{opacity:0 ,y:'-100%'}}
            transition={transition1}
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
          className='w-full pt-36 pb-14 lg:pt-0 lg:pb-0 lg:w-auto lg:absolute flex flex-col justify-center items-center lg:items-start'>
          <h1 className='h1'> Full Stack Developer<br /> & Web Designer</h1>
          <p className='text-[26px] lg:text-[36px] font-primary mb-4 lg:mb-12'>Delhi, INDIA</p>
          <Link to={'/contact'} className='btn mb-[36px]'>Hire me</Link>
          </motion.div>
          <div className='flex justify-end max-h-96 lg:max-h-max'>
            <motion.div 
             onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            initial={{scale:0}}
            animate={{scale:1}}
            exit={{scale:0}}
            transition={transition1}
            className='relative lg:right-40-0 overflow-hidden '>
              <motion.img 
              whileHover={{scale:1.1}}
              className='lg:w-[32rem]' src={HomeImg} alt='/'></motion.img>
            </motion.div>
          </div>
        </div>
      </div>
    </motion.section>
  )
}

export default Home
 