import React,{useContext} from 'react'

import Project1 from '../img/project/Screenshot (65).jpeg'
import Project2 from '../img/project/future.png'
import Project3 from '../img/project/Screenshot (66).jpeg'
import Project4 from '../img/project/squarespace.png'

import { Link } from 'react-router-dom'

import { motion } from 'framer-motion'

import {transition1} from '../transition'
import { CursorContext } from '../context/Cursor'

const Projects = () => {

  const{mouseEnterHandler,mouseLeaveHandler} = useContext(CursorContext)

  return (
    <motion.section
     initial={{opacity:0, y:'100%' }}
     animate={{opacity:1,y:0}}
     exit={{opacity:0,  y:'100%'}}
     transition={transition1}
     className='section'>
      <div className='container mx-auto h-full relative'>
        <div className='flex flex-col lg:flex-row h-full items-center justify-start gap-x-24 text-center lg:text-left pt-24 lg:pt-30 pb-8 '>
          <motion.div 
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
            initial={{opacity:0, y:'-80%' }}
            animate={{opacity:1,y:0}}
            exit={{opacity:0,  y:'80%'}}
            transition={transition1}
          className='flex flex-col lg:items-start'>
            <h1 className='h1'>Projects</h1>
            <p className='mb-12 max-w-sm '>Embark on a journey through my portfolio, where innovation meets precision. As a dynamic college senior, I've crafted cutting-edge websites and applications using MERN and different libraries. Seamlessly translating designs into vibrant front-end experiences, I bring a perfect blend of creativity and technical prowess, estimating time and costs with meticulous attention.<br/> <br/> Explore the intersection of design and development excellence in each project.</p>
            <Link to={'/contact'} className='btn mb-[30px] mx-auto lg:mx-0 '>Hire me</Link>
          </motion.div>
          <div
           onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
           className='grid grid-cols-2 lg:gap-2'>
            <div className='max-w-[280px] lg:max-w-[340px] h-[185px] lg:h-[220px] bg-accent'>
              <img src={Project1} className=' h-full lg:h-[220px] hover:scale-110 transition-all duration-500' alt='/' />
            </div>
            <div className='max-w-[280px] lg:max-w-[340px] h-[185px] lg:h-[220px] bg-accent'>
              <img src={Project2} className=' h-full lg:h-[220px] hover:scale-110 transition-all duration-500' alt='/' />
            </div>
            <div className='max-w-[280px] lg:max-w-[340px] h-[185px] lg:h-[220px] bg-accent'>
              <img src={Project4} className=' h-full lg:h-[220px] hover:scale-110 transition-all duration-500' alt='/' />
            </div>
            <div className='max-w-[280px] lg:max-w-[340px] h-[185px] lg:h-[220px] bg-accent'>
              <img src={Project3} className=' h-full lg:h-[220px] hover:scale-110 transition-all duration-500' alt='/' />
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  )
}

export default Projects
